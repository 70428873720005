@font-face {
    font-family: "Comfortaa-Bold";
    src: url("./fonts/Comfortaa/Comfortaa-Bold.woff");
    font-weight: 700;
}

@font-face {
    font-family: "Lato-Regular";
    src: url("./fonts/Lato/Lato-Regular.woff");
    font-weight: 400;
}

@font-face {
    font-family: "Lato-Medium";
    src: url("./fonts/Lato/Lato-Medium.woff");
    font-weight: 500;
}

@font-face {
    font-family: "Lato-Semibold";
    src: url("./fonts/Lato/Lato-Semibold.woff");
    font-weight: 600;
}

@font-face {
    font-family: "Lato-Bold";
    src: url("./fonts/Lato/Lato-Bold.woff");
    font-weight: 700;
}

@font-face {
    font-family: "Lato-Heavy";
    src: url("./fonts/Lato/Lato-Heavy.woff");
    font-weight: 800;
}

@font-face {
    font-family: "Lato-Black";
    src: url("./fonts/Lato/Lato-Black.woff");
    font-weight: 900;
}

@font-face {
    font-family: "Nexa-Bold";
    src: url("./fonts/Nexa/Nexa-Bold.otf");
    font-weight: 700;
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("./fonts/Roboto/Roboto-Bold.woff");
    font-weight: 700;
}

@font-face {
    font-family: "Gilroy-Regular";
    src: url("./fonts/Gilroy/Gilroy-Regular.woff");
    font-weight: 400;
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("./fonts/Gilroy/Gilroy-Medium.woff");
    font-weight: 500;
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url("./fonts/Gilroy/Gilroy-Bold.woff");
    font-weight: 700;
}