.btn_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.book_demo {
    width: 300px;
    max-width: 500px;
    padding: 20px 25px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--blue);
    border: none;
    border-radius: 3px;
    font-family: var(--f600-lato);
    font-weight: 300;
    font-size: 24px;
    line-height: 21px;
    color: var(--white);
    cursor: pointer;
}