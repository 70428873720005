.heading5 {
    font-weight: 400;
    margin: -1.5rem auto;
    font-size: 18px;
}

.heading1 {
    font-weight: 900;
    transform: scale(0.9);
    line-height: 2.5rem;
}

button {
    transform: scale(0.75, 0.7);
}

.container404 {
    display: flex;
    overflow: hidden !important;
}

.seventy {
    width: 70%;
    height: 100vh;
    background: #f1f1f1;
    display: inline-block;
    text-align: center;
}

.content404 {
    margin-top: 30vh;
    font-family: sans-serif;
    transform: scale(2);
    font-size: large;
    margin-left: -10rem;
}

.pink-btn {
    border: none;
    outline: none;
    color: white;
    background: #ff4c6b;
    padding: 0.2rem 1rem;
    border-radius: 2rem;
    cursor: pointer;
    margin-top: 2rem;
    transform: scale(1.2);
    width: 6rem;
}

.pink-btn span {
    font-size: 0.7rem;
}

.thirty {
    width: 30%;
    height: 100vh;
    background: rgb(71, 62, 158);
    display: inline-block;
}

.carbon-img {
    position: absolute;
    overflow: hidden;
    width: 30vw;
    top: 15%;
    left: 54.5vw;
}

@media only screen and (max-width: 600px) {
    .carbon-img {
        position: absolute;
        overflow: hidden;
        width: 60vw;
        top: 10%;
        left: 21.5vw;
    }

    .content404 {
        position: relative;
        width: 23rem;
        overflow: hidden;
        margin: 23rem 0 0 2rem;
        transform: scale(1.3);
        font-size: inherit;
    }
}

.carbon-img {
    border-radius: 50%;
}