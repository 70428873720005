/* @import url(font.css); */

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
}

:root {
  --blue: #473e9e;
  --light-blue: #3291e9;
  --white: #fff;
  --black: #000;
  --nav-link: #3a3a3a;
  --purple: #918bc5;
  --purple-opacity-30: rgba(145, 139, 197, 0.3);
  --purple-opacity-20: rgba(145, 139, 197, 0.2);
  --light-purple: #bcbfd8;
  --red: #ee2654;
  --pink: #ff819e;
  --yellow: #fab011;
  --grey: #e7e5f4;
  --grey-2: #e4e3f7;
  --grey-3: #faf9ff;
  --f400-lato: 'Lato-Regular';
  --f500-lato: 'Lato-Medium';
  --f600-lato: 'Lato-Semibold';
  --f700-lato: 'Lato-Bold';
  --f800-lato: 'Lato-Heavy';
  --f900-lato: 'Lato-Black';
  --main-input-bg: #f2f1ff;
  --btn-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
  --bg-other-main: #f2f1ff;
  --reason-input-bg: #fff;
  --reason-input-border: #e7e5f4;
  --reason-input-circle: #dad8ec;
  --loading-bg: #edecff;
  --bg-item: #f3f2ff;
}

a,
a:hover {
  text-decoration: none !important;
}

p,
ul {
  margin-bottom: 0;
}

ol,
ul {
  padding-left: 0;
}

body {
  min-width: 375px;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.workspace {
  height: 100vh;
  /* width: 100vw */
}

.content {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  -o-transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s,
    -webkit-transform 1s ease 0s;
}

.content.slide-up {
  -webkit-transform: translateY(-100vh);
  -ms-transform: translateY(-100vh);
  transform: translateY(-100vh);
  pointer-events: none;
}

.content.hidden {
  display: none;
}

.side {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  height: 100%;
  overflow: hidden;
}

.left {
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--blue);
}

.left__inner {
  position: relative;
  height: 100%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  padding: 10% 25px 15%;
  max-width: 850px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: 0.5s;
}

/* 
.left__content {
    max-width: 515px;
    width: 100%;
    position: relative;
    z-index: 5
} */
.left__content {
  max-width: 515px;
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 10%;
  /*10%;*/
  left: 8%;
}

.icon__bg {
  position: absolute;
  width: 430px;
  height: 530px;
  fill: var(--white);
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  -webkit-animation: infinite motion 10s;
  animation: infinite motion 10s;
}

.icon__bg-L {
  left: -20%;
  top: -10%;
}

.icon__bg-R {
  right: -20%;
  bottom: -150px;
}

.dashboard {
  position: absolute;
  width: 100%;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  bottom: -315px;
}

.dashboard-1-animate {
  left: -40% !important;
}

.dashboard-1 {
  left: 40%;
  /*40% */
  -webkit-transform: translateX(-85%);
  -ms-transform: translateX(-85%);
  transform: translateX(-85%);
}

.dashboard-2-animate {
  left: 10% !important;
}

.dashboard-2 {
  left: 65%;
  /*65% */
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.dashboard.hide {
  top: 110%;
}

.logo {
  max-width: 250px;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  margin-bottom: 30px;
}

.title {
  width: 100%;
  font-family: var(--f800-lato);
  font-weight: 800;
  font-size: 50px;
  line-height: 65px;
  letter-spacing: 0.05em;
  color: var(--white);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.title.hide {
  -webkit-transform: translateX(-100vw);
  -ms-transform: translateX(-100vw);
  transform: translateX(-100vw);
}

.icon__arrow {
  width: 14px;
  height: 14px;
  stroke: var(--white);
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
}

.right {
  width: 100%;
  height: 100%;
  position: relative;
}

.right__content {
  width: 100%;
  /* height: calc((100% - 120px)); */
  /* overflow-y: auto; */
  padding-top: 50px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  -o-transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s,
    -webkit-transform 1s ease 0s;
}

.right__content.slide-up {
  -webkit-transform: translateY(-100vh);
  -ms-transform: translateY(-100vh);
  transform: translateY(-100vh);
  pointer-events: none;
}

.right__content.slide-down {
  -webkit-transform: translateY(100vh);
  -ms-transform: translateY(100vh);
  transform: translateY(100vh);
  pointer-events: none;
}

#right-1 {
  padding-top: 130px;
}

.inner {
  max-width: 500px;
  width: 100%;
  padding: 25px;
}

.inner__title {
  font-family: var(--f700-lato);
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 20px;
  color: var(--blue);
}

.right__title {
  margin-bottom: 0px !important;
  font-size: 22px !important;
}

.right_title_main {
  padding-bottom: 20px !important;
}

.inner__desc {
  font-family: var(--f600-lato);
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--purple);
}

.btn__continue {
  width: 100%;
  max-width: 170px;
  padding: 10px 25px 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--blue);
  border: none;
  border-radius: 3px;
  font-family: var(--f600-lato);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--white);
  cursor: pointer;
  z-index: 9999;
  transform: none !important;
  margin-top: 35px;
}

#right-1 .inner__title {
  margin-bottom: 26px;
}

.inner__box {
  /* margin-bottom: 55px; */
}

.radio__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.radio__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 35px;
  cursor: pointer;
}

.radio__label:last-child {
  margin-right: 0;
}

.radio__input {
  display: none;
}

.radio__icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--grey-2);
  position: relative;
  margin-right: 10px;
}

.radio__icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--light-blue);
  opacity: 0;
}

.radio__input:checked+.radio__icon {
  border: 2px solid var(--light-blue);
}

.radio__input:checked+.radio__icon::after {
  opacity: 1;
}

.radio__text,
.checkbox__text {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--blue);
}

.signup__sub {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: var(--white);
  padding-top: 20px;
  opacity: 0.8;
  letter-spacing: 0.04rem;
}

.number {
  position: relative;
  min-width: 131px;
  width: fit-content;
}

.window__day {
  position: absolute;
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: var(--purple);
  top: 0;
  height: 100%;
  left: 55px;
  background: transparent;
  padding: 0 15px;
  letter-spacing: 0.05em;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-left: 2px solid var(--grey-2); */
}

.number__input {
  width: 28%;
  padding: 14px;
  border: 2px solid var(--grey-2);
  border-radius: 3px;
  font-family: var(--f700-lato);
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--black);
  text-align: center;
}

.number__input:focus {
  outline: none;
  border: 2px solid var(--purple);
}

.number__input:focus~.window__day {
  /* border-left: 2px solid var(--purple); */
}

.number::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 14px;
  width: 30px;
  height: 21px;
  z-index: 0;
  /* background: var(--purple-opacity-20); */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

.number__btn {
  position: absolute;
  width: 46px;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 0;
  z-index: 5;
  cursor: pointer;
}

.number__icon {
  width: 18px;
  height: 9px;
  fill: var(--purple);
  right: 14px;
}

.number__top .number__icon {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.number__top {
  top: 0;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 2px;
}

.number__bottom {
  bottom: 0;
  padding-top: 2px;
}

.checkbox__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* max-height: 45vh;
  overflow-y: auto; */
  margin-bottom: 15px;
}

.checkbox__label {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  width: 50%;
}

.checkbox__input {
  display: none;
}

.checkbox__span {
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  border: 2px solid var(--grey-2);
  margin-right: 10px;
}

.checkbox__icon {
  width: 18px;
  height: 8px;
  fill: var(--white);
}

.checkbox__input:checked+.checkbox__span {
  border: 2px solid var(--light-blue);
  background: var(--light-blue);
}

.bottom__panel.dont-show {
  display: none;
}

.bottom__panel {
  /* height: 100px; */
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0px -3px 10px var(--white);
  box-shadow: 0px -3px 10px var(--white);
  z-index: -1;
}

.bottom__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
  padding: 0 25px;
}

.answer {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  max-height: 0;
}

.answer.show {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 150px;
  margin-top: 50px;
}

.answer.visible {
  opacity: 1;
  visibility: visible;
}

#right-5 .inner {
  max-width: 550px;
}

#right-5 .inner__title {
  max-width: 370px;
}

.add {
  width: 100%;
  position: relative;
}

.add__search {
  max-width: 350px;
  width: 100%;
  position: relative;
  margin-bottom: 28px;
}

.add__input {
  width: 100%;
  border: 2px solid var(--grey-2);
  border-radius: 3px;
  padding: 14px 35px 14px 14px;
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
  background: var(--main-input-bg);
  text-align: left;
}

.add__input::-webkit-input-placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.add__input::-moz-placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.add__input:-ms-input-placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.add__input::-ms-input-placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.add__input::placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.add__input:focus {
  outline: none;
  border: 2px solid var(--purple);
}

.add__icon {
  width: 10px;
  height: 10px;
  fill: none;
  stroke: var(--blue);
}

.add__list {
  position: absolute;
  width: 100%;
  background: var(--white);
  z-index: 5;
  border: 1px solid var(--grey-2);
  border-radius: 5px;
  overflow: auto;
  top: 105%;
  -webkit-box-shadow: 0px 0px 50px var(--bg-other-main);
  box-shadow: 0px 0px 50px var(--bg-other-main);
  display: none;
  max-height: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-box-shadow: 0px 4px 10px #e4e3f7;
  box-shadow: 0px 4px 10px #e4e3f7;
}

.add__list.visible {
  display: block;
}

.add__list::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.add__list::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
  background: var(--blue);
}

.add__item {
  width: 100%;
  /* border-bottom: 1px solid var(--grey-2); */
  padding: 10px;
  font-family: var(--f400-lato) !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.2px;
  color: var(--blue);
  text-align: left;
  cursor: pointer;
}

.add__item:last-child {
  border-bottom: none;
}

.add__item.selected {
  background: var(--purple-opacity-20);
}

.add__item:hover {
  background: var(--light-blue);
  color: var(--white);
}

.add__item.choose {
  background: var(--light-purple);
  color: var(--white);
}

.add__item.choose:hover {
  background: var(--blue);
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--blue);
  position: absolute;
  right: 14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

#target__list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: auto;
}

#target__list::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

#target__list::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
  background: var(--blue);
}

.target__item {
  border-radius: 50px;
  padding: 8px 14px;
  background: var(--bg-item);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--blue);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 14px;
  margin-bottom: 10px;
}

.target__btn {
  position: static;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 10px;
}

.target__icon {
  width: 8px;
  height: 8px;
  fill: var(--blue);
}

.target__btn:hover {
  border: 1px solid var(--red);
}

.target__btn:hover .target__icon {
  fill: var(--red);
}

.add__warning {
  position: absolute;
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  padding: 10px;
  border-radius: 5px;
  background: var(--purple);
  top: -75%;
  right: -10px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.add__warning::before,
.add__warning::after {
  content: '';
  position: absolute;
  right: 25px;
  bottom: -20px;
  border: 10px solid transparent;
  border-top: 10px solid var(--purple);
}

.add__warning::after {
  border-top: 10px solid var(--purple);
  bottom: -19px;
}

.add__warning.show {
  top: -50%;
  opacity: 1;
  visibility: visible;
}

#right-6 {
  overflow: auto;
}

#right-6 .inner {
  max-width: 530px;
  padding: 0 25px;
}

#right-6 .question {
  margin-bottom: 20px;
}

#right-6 .inner__title {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
}

.billing,
.finaly {
  position: relative;
  width: 100%;
  /* height: 100vh; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 25px 100px;
  background: var(--white);
  /* overflow-x: hidden;
    overflow-y: auto; */
  -webkit-transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  -o-transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s,
    -webkit-transform 1s ease 0s;
  /* overflow: hidden; */
}

.overflow-yes {
  overflow: hidden !important;
}

.billing.slide-up {
  overflow: hidden;
  -webkit-transform: translateY(-100vh);
  -ms-transform: translateY(-100vh);
  transform: translateY(-100vh);
  pointer-events: none;
}

.billing.slide-down,
.finaly.slide-down {
  overflow: hidden;
  -webkit-transform: translateY(100vh);
  -ms-transform: translateY(100vh);
  transform: translateY(100vh);
  /* pointer-events: none */
}

.finaly.hidden {
  display: none;
}

.billing .icon__bg,
.finaly .icon__bg {
  position: absolute;
  fill: var(--light-blue);
}

.billing__bg-L,
.finaly__bg-L {
  left: -10%;
  top: -15%;
}

.billing__bg-R,
.finaly__bg-R {
  right: -10%;
  bottom: -15%;
}

.billing .logo,
.finaly .logo {
  margin-bottom: 0;
}

.billing__title,
.finaly__title {
  font-family: var(--f800-lato);
  font-weight: 800;
  font-size: 36px;
  line-height: 46.8px;
  letter-spacing: 0.05em;
  color: var(--blue);
  text-align: center;
  max-width: 700px;
  width: 100%;
  margin-bottom: 15px;
}

.finaly__redirect {
  font-family: var(--f500-lato);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--purple);
  margin-bottom: 29px;
  margin-top: 100px;
}

.finaly__desc {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--nav-link);
  max-width: 850px;
  width: 100%;
  margin-bottom: 16px;
}

.finaly__seconds {
  font-weight: bold;
  color: var(--blue);
  font-size: 36px;
  line-height: 54px;
}

.booking {
  width: 100%;
}

.booking__title {
  font-family: var(--f700-lato);
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--blue);
  margin-bottom: 35px;
}

.booking__date {
  display: inline-block;
  width: 100%;
  font-family: var(--f500-lato);
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  color: var(--purple);
  margin-bottom: 17px;
  text-align: center;
}

.bookingSwiper {
  max-width: 800px;
  width: 100%;
  position: relative;
  padding: 5px 29px;
  margin-bottom: 44px;
}

.daySwiper {
  margin-bottom: 25px;
}

.swiper__radio {
  display: none;
}

.booking__box {
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--grey);
  padding: 15px;
  font-family: var(--f500-lato);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--blue);
  cursor: pointer;
}

.swiper__radio:checked+.booking__box {
  border: 1px solid var(--blue);
}

.booking__button-next::after,
.booking__button-prev::after {
  display: none;
}

.booking__button-prev {
  left: 0;
}

.booking__button-next {
  right: 0;
}

.swiper__btn-R,
.swiper__btn-L {
  width: 21px;
  height: 19px;
  fill: var(--blue);
}

.swiper__btn-R {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.booking__send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--purple-opacity-20);
  padding: 20px;
  z-index: 2;
  -webkit-box-shadow: 0px 10px 20px #c6c6ca;
  box-shadow: 0px 10px 20px #c6c6ca;
}

.booking__send::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  backdrop-filter: blur(3px);
}

.booking__email {
  max-width: 270px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 30px;
}

.booking__email-text {
  font-family: var(--f500-lato);
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--blue);
  margin-bottom: 10px;
}

.booking__email-input {
  width: 100%;
  padding: 13px;
  border: 2px solid var(--grey);
  border-radius: 3px;
  background: var(--white);
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.booking__email-input:focus {
  outline: none;
  border: 2px solid var(--blue);
}

.booking__email-input::-webkit-input-placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.booking__email-input::-moz-placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.booking__email-input:-ms-input-placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.booking__email-input::-ms-input-placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.booking__email-input::placeholder {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.booking__send-btn {
  max-width: 270px;
  width: 100%;
  padding: 13px;
  text-align: center;
  font-family: var(--f700-lato);
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: var(--white);
  background: var(--blue);
  border: 2px solid var(--blue);
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  -webkit-box-shadow: 0 0 40px 40px var(--blue) inset, 0 0 0 0 var(--blue);
  box-shadow: 0 0 40px 40px var(--blue) inset, 0 0 0 0 var(--blue);
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.booking__send-btn.confirm {
  text-transform: uppercase;
  background: var(--light-blue);
  border: 2px solid var(--light-blue);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.curent-time {
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: var(--purple);
  text-align: center;
  margin-bottom: 15px;
}

.curent-time__icon {
  width: 18px;
  height: 18px;
  fill: none;
  stroke: var(--purple);
}

#browser__tab {
  color: red;
}

.billing__desc {
  font-family: var(--f500-lato);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--purple);
  max-width: 750px;
  width: 100%;
  margin-bottom: 18px;
}

.billing__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
}

.billing__card {
  padding: 32px 42px 40px;
  margin: 0 15px 30px;
  background: var(--white);
  border: 2px solid var(--grey);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 350px;
  width: 100%;
}

.billing__card:hover {
  border: 2px solid var(--blue);
}

.billing__name {
  font-family: var(--f700-lato);
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: var(--blue);
  margin-bottom: 16px;
}

.billing__rate {
  margin-bottom: 10px;
}

.billing__currency,
.billing__price {
  font-family: var(--f700-lato);
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  color: var(--blue);
}

.billing__mark,
.billing__date {
  font-family: var(--f500-lato);
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--purple);
}

.billing__list {
  padding-left: 25px;
  margin-bottom: 36px;
}

.billing__item {
  list-style: disc;
  font-family: var(--f400-lato);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: var(--blue);
}

.line {
  width: 100%;
  margin-top: 12px;
  opacity: 0.5;
}

.signup__heading, .signin__heading, .fp__heading, .fu__heading {
  font-family: var(--f800-lato);
  font-size: 36px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: var(--blue);
  padding: 0 0 15px 15px;
}

.signin__text {
  color: var(--blue);
}

.fu__heading {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signup__input, .signin__input, .fp__input, .fu__input {
  padding: 15px 0 15px 15px;
  width: 100%;
}

.fp__input, .fu__input {
  padding-top: 0px;

}

.signin__input, .fp__input, .fu__input {
  width: 75%;
}

.signup__input__flex, .signin__input__flex {
  display: flex;
  justify-content: space-between;
}

.signin__input__flex {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fp_main {
  display: flex;
  padding: 10px 55px;
  font-size: 12px;
}

.fu_main {
  display: flex;
  padding-top: 10px;
  font-size: 12px;
  font-family: 'Lato-Regular';

}

.signin_btn_main, .fp_btn_main, .fu_btn_main {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 54px;
}

.signin_btn, .fp_btn, .fu_btn {
  width: 100% !important;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fp_btn, .fp_btn {
  margin-top: 0;
}

.fp_instruction, .fu_instruction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: var(--blue);
  padding: 15px 55px;
}

.forgot_password_main {
  display: flex;
  padding: 10px 55px;
  font-size: 12px;
}

.text__wrapper {
  width: 100%;
}

.text__label {
  width: 100%;
}

.text__label__heading, .fp_label {
  font-family: var(--f500-lato);
  font-size: 18px;
  line-height: 150%;
  color: var(--blue);
}

.fp_label {
  padding: 0 20px;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text__input {
  width: inherit;
  border: 2px solid #e4e3f7;
  border-radius: 3px;
  padding: 10px;
  font-family: var(--f400-lato);
  color: var(--purple);
  outline: none;
}

.text__input::placeholder {
  color: var(--purple);
}

.code_red {
  color: red !important;
}

.signup__inner, .signin__inner, .fp__inner, .fu__inner {
  margin: 0 5%;
}

.signup__content {
  padding-top: 80px;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  -webkit-transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  -o-transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s,
    -webkit-transform 1s ease 0s;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 95vh;
  padding-right: 15px;
}

.signin__content, .fp__content, .fu__content {
  padding-top: 80px;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  -webkit-transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  transition: opacity 300ms ease 0s, -webkit-transform 1s ease 0s;
  -o-transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s;
  transition: transform 1s ease 0s, opacity 300ms ease 0s,
    -webkit-transform 1s ease 0s;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-right: 15px;
}


.signup__input__explain, .signin__input__explain {
  font-family: var(--f400-lato);
  font-size: 14px;
  line-height: 0.5;
  color: var(--purple);
  padding: 2px 0;
}

.signup__checkbox__text {
  font-family: var(--f400-lato);
  font-size: 14px !important;
  line-height: 140%;
  color: var(--purple);
}

.signup__checkbox {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* margin-bottom: 20px; */
  width: fit-content;
  padding: 15px 0;
  /* margin: 0 5%; */
}

.signup__input__submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 49px; */
}

.signup__checkbox__span {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  width: 100%;
  color: var(--blue);
  font-family: var(--f700-lato);
}

.signup__button {
  margin-right: 20px;
  width: 100%;
}

@-webkit-keyframes motion {
  from {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes motion {
  from {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@media (max-width: 1100px) {
  .side:first-child {
    display: none;
  }

  .right__content {
    padding-top: 50px !important;
  }
}

@media (max-width: 767px) {
  .booking__send {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .booking__email {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .finaly {
    padding-bottom: 200px;
  }

  .billing .icon__bg,
  .finaly .icon__bg {
    display: none;
  }
}

@media (max-width: 576px) {
  .workspace {
    width: 100%;
  }

  .billing .logo,
  .finaly .logo {
    margin-bottom: 20px;
  }

  .billing__title,
  .finaly__title {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 20px;
  }

  .finaly__redirect {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .finaly__seconds {
    line-height: 48px;
    font-size: 30px;
  }

  .finaly__desc {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .booking__title {
    max-width: 250px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .billing__inner {
    padding: 0;
  }

  .billing__card {
    margin: 0;
    margin-bottom: 30px;
  }

  .billing__desc {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (min-height: 900px) and (min-width: 700px) {
  .booking__send {
    position: static;
    box-shadow: none;
    background: transparent;
    backdrop-filter: none;
  }

  .booking__send::after {
    display: none;
  }
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  line-height: 25px;
  height: 48px !important;
  width: 48% !important;
  outline: none;
  border-radius: 3px !important;
  padding: 10px 10px 10px 48px !important;
  font-family: var(--f400-lato) !important;
  color: var(--purple) !important;
}

@media screen and (min-width: 1200px) and (max-width: 1365px) {
  .checkbox__wrapper {
    max-height: 45vh;
    overflow-y: auto;
  }
}

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .checkbox__wrapper {
    max-height: 30vh;
    overflow-y: auto;
  }
}

/*Scrollbar Style */
.checkbox__wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.checkbox__wrapper::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.checkbox__wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.44, var(--blue)),
      color-stop(0.72, var(--blue)),
      color-stop(0.86, var(--blue)));
}

/* .checkbox__wrapper:hover {
  overflow-y: scroll;
} */

.page {
  height: 100vh;
  background-color: var(--white);
  font-family: var(--f800-lato);
}

.page__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page__container__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 25rem;
  width: 35%;
  border-radius: 0.5rem;
}

.page__container__content__header {
  width: 80%;
  display: flex;
  justify-content: center;
}

.page__container__content__header__logo {}

.page__container__content__form {
  width: 80%;
  margin: 3rem 0 0 0;
}

.page__container__content__form__header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.page__container__content__form__header__heading {
  color: white;
  font-size: 1.2rem;
  color: var(--black);
}

.page__container__content__form__main {
  margin: 1rem 0 0 0;
}

.page__container__content__form__main__section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page__container__content__form__main__section__input {
  padding: 0.8rem 2rem;
  border: none;
  outline: 1px solid var(--blue);
  border-radius: 0.2rem;
  font-size: 1rem;
  font-weight: 600;
}

.page__container__content__form__main__section__text {
  color: var(--black);
  font-size: 1rem;
  margin-left: 0.5rem;
}

.page__container__content__form__main__action {
  margin: 1.5rem 0 0 0;
  display: flex;
  justify-content: center;
}

.page__container__content__form__main__action__item {
  outline: none;
  border: none;
  padding: 1rem 2rem;
  background-color: var(--blue);
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 0.2rem;
  cursor: pointer;
}

.page__container__content__form__main__action__item[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.page__container__content__form__error {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.page__container__content__form__error__text {
  color: #ee3939;
}

.sign-in-link-main {
  display: flex;
  padding: 15px 0px;
}

.signin_error_msg {
  padding-left: 51px;
}

.sign-in-link-main>a {
  cursor: pointer;
}

.term_and_signin_block {
  display: flex;
  flex-direction: column;
  padding: 15px 50px;
}

.sign-in-link-main>span {
  color: #473e9e;
}

.input-icons img {
  position: absolute;
  right: 220px;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  min-width: 50px;
  text-align: center;
}